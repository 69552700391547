import React from 'react';
import ListeVoiture from './components/listeVoiture';  // Si vous avez un composant ListeVoiture

const App = () => {
  return (
    <div>
      <h1>Bienvenue dans le suivi des entretiens des voitures</h1>
      <ListeVoiture /> {/* Assurez-vous d'afficher quelque chose ici */}
    </div>
  );
};

export default App;
