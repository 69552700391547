import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListeVoiture = () => {
  const [voitures, setVoitures] = useState([]);  // État pour les voitures
  const [error, setError] = useState(null);  // État pour l'erreur

  useEffect(() => {
    // Récupérer les voitures
    axios
  .get('http://localhost:5001/api/voitures')  // Utilisez le bon port (5001)
  .then((response) => {
    setVoitures(response.data);  // Stocke les données des voitures dans l'état
  })
  .catch((error) => {
    console.error('Error fetching cars:', error);
    setError('Erreur lors du chargement des voitures');  // Affiche un message d'erreur
  });

  }, []);  // Le tableau vide signifie que l'effet se déclenche une seule fois

  if (error) {
    return <div>{error}</div>;  // Affiche l'erreur si elle existe
  }

  return (
    <div>
      <h1>Liste des Voitures</h1>
      <ul>
        {voitures.map((voiture) => (
          <li key={voiture.id}>{voiture.marque} - {voiture.couleur} - {voiture.lieu} </li>  // Affiche chaque voiture
        ))}
      </ul>
    </div>
  );
};

export default ListeVoiture;
